import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Select from '@/shared/components/select/Select';
import ButtonAdd from '../../../../pdp/ButtonAdd';
import Button from '@/shared/components/button/Button';
import Modal from '@/shared/components/modal/Modal';
import { Autocomplete, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import type {
  ClientResponse,
  ProductProjection,
  ProductProjectionPagedSearchResponse,
} from '@commercetools/platform-sdk';
import { crErrorToastStyles, crSuccessToastStyles, notifyError, notifySuccess } from '../../toast/Toast';

import { addProductToCartWithShippingAddress, loadCart, loadBusinessUnits } from '@/shared/functions/commerceTools';
import { AppContext } from 'context/appContext/AppContextProvider';
import { apiRoot } from '@/shared/client';
import { PRODUCT_TYPE } from '@/shared/consts/CONST_PROJECT.json'

interface ISetEvents {
  setShowLoader: any;
  setComponent: any;
}

interface Option {
  idProduct: string;
  idVariant: string;
  key: string;
  name: string;
  sku?: string;
}

const CompraBySku = ({ setShowLoader, setComponent }: ISetEvents) => {
  const context = useContext(AppContext);
  const inputRef = useRef<any>();
  const [quantity, setQuantity] = useState(0);
  const [modalAddToCart, setModalAddToCart] = useState(false);
  const [selectBussinesUnit, setSelectBussinesUnit] = useState('');
  const [sku, setSku] = useState('');
  const [options, setOptions] = useState<Option[]>([]);
  const [results, setResults] = useState<ProductProjection[]>();


  const [minimalquantity, setMinimalquantity] = useState<number>(1);
  const [incremental, setIncremental] = useState<number>(1);


  const handleClickAddToCart = async () => {
    try {
      setShowLoader(true);
      if (sku === '' || selectBussinesUnit === '') throw Error('Sku o Sucursal no seleccionado');
      // Llama a la función para agregar al carrito
      let includeShipping = false;

      const businessSelected = context.appContext.bussinessUnits.find(
        (business) => business.bussinessUnit.key == selectBussinesUnit,
      );

      const existInCart = context.appContext.cart?.lineItems?.find((item) => item.variant.sku == sku);

      if (existInCart && businessSelected) {
        businessSelected.bussinessUnit.addresses.forEach((business) => {
          includeShipping =
            existInCart.shippingDetails?.targets.some((target) => target.addressKey === business.id) || false;
        });
      }

      if (!includeShipping) {
        await handleAddToCart();
      } else {
        setModalAddToCart(true);
      }
    } catch (err: any) {
      notifyError(err.message)
    } finally {
      setShowLoader(false);
    }
  };

  const handleAddToCart = async () => {
    try {
      const addProductRes = await addProductToCartWithShippingAddress({
        quantity,
        sku,
        addressKey: selectBussinesUnit,
      });
      if (!addProductRes.cart) {
        notifyError(addProductRes?.errMessage ?? '', crErrorToastStyles);
        return;
      }

      notifySuccess('Producto agregado al carrito', crSuccessToastStyles);
      context.setCart(addProductRes.cart);
      setComponent('by-resumen');
    } catch (error: any) {
      notifyError(`El producto con sku ${sku} no existe`, crErrorToastStyles);
    }
  };



  const sdk_getProducts = (
    querySearch: string,
  ): Promise<ClientResponse<ProductProjectionPagedSearchResponse> | string> => {
    // const loginEncrypt = getCookieValue(findCookie('login'));
    // const login = decryptWithAES(loginEncrypt);
    // const { Email, Password } = ctCredentials(login);

    return new Promise(async (resolve, reject) => {
      if (typeof querySearch !== 'string') return reject('');
      if (!querySearch?.trim()) return reject('');

      let fuzzyLevel: number = 0;
      if (querySearch.length > 5) fuzzyLevel = 2;
      if (querySearch.length >= 3 && querySearch.length < 5) fuzzyLevel = 1;
      if (querySearch.length < 3) fuzzyLevel = 0;
      let queryArgs: any
      debugger
      if (!Array.isArray(querySearch) && !isNaN(Number(querySearch))) {
        queryArgs = {
          filter: [`variants.sku: ${querySearch}`]
        }
      } else {
        queryArgs = {
          ...queryArgs,
          ...{
            'text.es-MX': querySearch,
            fuzzy: true,
            fuzzyLevel,
          },
        };
      }
      try {
        const resProducts = await apiRoot
          .productProjections()
          .search()
          .get({
            queryArgs
          })
          .execute();

        resolve(resProducts);
      } catch (e: any) {
        reject('error: ' + e.message);
      }
    });
  };

  let idTimeout: any;
  const handleInputChange = (e: any) => {
    // @ts-ignore
    clearTimeout(idTimeout);

    // @ts-ignore
    idTimeout = setTimeout(async () => {
      const res = await sdk_getProducts(e.target.value);
      if (typeof res === 'string') {
        notifyError('error al obtener productos: ' + res);
        return;
      }
      if (res.statusCode !== 200) {
        notifyError('error al obtener productos');
        return;
      }
      setResults(res.body.results);
    }, 400);
  };

  useEffect(() => {
    if (results?.length === 0 || !results) return;

    let newOptions: Option[] = [];

    results.forEach((product) => {
      if (product)
        newOptions.push({
          idProduct: product.id.toString(),
          idVariant: product.masterVariant.id.toString(),
          key: product.masterVariant.key || "",
          name: product?.name?.["es-MX"] || product.key || "",
          sku: product.masterVariant.sku,
        });

      product.variants.forEach((variant) => {
        if (variant.key)
          newOptions.push({
            idProduct: product.id,
            idVariant: variant.id.toString(),
            key: variant.key,
            name: variant.attributes?.find((attr) => attr.name === 'name')?.value || product.key,
            sku: variant.sku,
          });
      });
    });

    setOptions(newOptions);
  }, [results]);

  const loadProductInfo = async (sku: string) => {
    const loadProductsReq = await apiRoot.productProjections().get({
      queryArgs: {
        where: `masterVariant(sku in (${sku}))`,
        limit: 1
      }
    }).execute()
    const res = loadProductsReq.body.results[0]

    const incremental = res.masterVariant.attributes?.find(attr => attr.name === "incremental")?.value || 1
    const minimalquantity = res.masterVariant.attributes?.find(attr => attr.name === "minimalquantity")?.value || 1
    setIncremental(incremental)
    setMinimalquantity(minimalquantity)
    setQuantity(minimalquantity)

  }
  useEffect(() => {
    if (!sku) return
    loadProductInfo(sku)

  }, [sku])

  return (
    <>
      <div className={styles['container-headers']}>
        <div className={styles['header']}>
          <div>
            <div
              onClick={() => {
                setComponent('select');
              }}
              className={styles['container-close-icon']}
            >
              <p className={styles['text-compra-rapida-icon']}>&lt;</p>
              <p className={styles['text-compra-rapida']}>Comprar por búsqueda de SKU</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['container-body']}>
        <div className={styles['sku-body-container']}>
          <span className={styles['text-entry']}>
            Agrega productos a tu carrito con el buscador, puedes buscar por nombre de producto, SKU, modelo.
          </span>
          {/* <div className={styles['container-sku']}>
            <label className={styles['content-text']}>Ingresa el nombre o SKU del producto</label>
            <input
              className={styles['search-input']}
              type="search"
              placeholder="Buscar por producto, SKU o modelo"
              aria-label="Search"
              value={sku}
              onChange={(e) => {
                setSku(e.target.value);
              }}
            />
          </div> */}
          <div className={styles['fastbuysku-input']}>
            {/* <Search placeholder="Buscar" label="Nombre de producto o SKU" onChange={(e) => handleChangeInput(e)} /> */}
            <Autocomplete
              PaperComponent={({ children }) => <Paper style={{ width: '403px' }}>{children}</Paper>}
              ref={inputRef}
              size="small"
              disablePortal
              id="combo-box-demo"
              options={options}
              noOptionsText="No hay opciones"
              onInputChange={handleInputChange}
              // filterOptions={(options) => options}
              getOptionLabel={(option: Option) => {
                console.log(option)
                return `${option.sku} - ${option.name}` || `${option.sku} - ${option.key}`
              }
              }
              onChange={(_: any, val: any) => {
                if (val?.sku) setSku(val.sku);
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField style={{ width: '100%' }} {...params} label="Buscar" />}
            />
          </div>
          <div className={styles['container-sku']}>
            <label className={styles['content-text']}>Selecciona una sucursal para este producto</label>
            <Select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const findBussines = context.appContext.bussinessUnits?.find(
                  (item) => item.bussinessUnit.name === e.target.value,
                );
                setSelectBussinesUnit(findBussines?.bussinessUnit.key || '');
              }}
              options={
                context.appContext.bussinessUnits
                  ? [
                    'Selecciona una opcion',
                    ...context.appContext.bussinessUnits.map((bu) => {
                      return bu.bussinessUnit.name;
                    }),
                  ]
                  : ['Selecciona una opcion']
              }
              style={{ width: '100%' }}
            />
          </div>
          <div className={styles['container-buttons']}>
            <div className={styles['count-button']}>
              <ButtonAdd
                quantity={quantity}
                onAdd={() => {
                  if (!sku) return
                  setQuantity(quantity + incremental)
                }}
                onRemove={() => {
                  if (!sku) return
                  setQuantity(Math.max(minimalquantity, quantity - incremental));

                }}
              />
            </div>
            <div className={styles['cart-button']}>
              <Button
                variant="secondary"
                onClick={() => {
                  handleClickAddToCart();
                }}
              >
                Añadir al carrito
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        onClick={handleAddToCart}
        label1="¿Estás seguro de volver a agregar este producto a tu carrito?"
        labelButton1="Agregar producto"
        labelButton2="Cancelar"
        open={modalAddToCart}
        onClose={() => {
          setModalAddToCart(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      ></Modal>
    </>
  );
};

export default CompraBySku;
